@import "variables";

/*
    LOADING CIRCLE
 */

$base-line-height: 24px;
$base-line-height-small: 18px;
$base-line-height-large: 38px;
$white: rgb(255, 255, 255);
$dark: rgb(0,0,0);
$off-white: rgba($white, 0.2);
$off-dark: rgba($dark, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border-radius: 50%;
  width: $base-line-height;
  height: $base-line-height;
  border: .25rem solid $off-white;
  border-top-color: $white;
  animation: spin $spin-duration infinite cubic-bezier(0, 0, 0, 0.65) infinite;
  &.spinner-dark {
    border: .25rem solid $off-dark;
    border-top-color: $dark;
  }
  &.spinner-small {
    width: $base-line-height-small;
    height: $base-line-height-small;
    border-width: .15rem;
  }
  &.spinner-large {
    width: $base-line-height-large;
    height: $base-line-height-large;
    border-width: .45rem;
  }
}
[class*="button_type"]:not(.transparent):hover {
  .spinner {
    border: .25rem solid $off-dark;
    border-top-color: $dark;
  }
}

#pageLoadingOverlap {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.8);
  z-index: 9999;
}

/*
    GENERALE
 */
a:focus, a:hover{
  text-decoration: none;
}

.has-success {
  input[type="radio"]:checked + label:before,
  input[type="checkbox"]:checked + label:before {
    border-color: #0a9c0d;
  }
}
.custom_select {
  .select_title {
    white-space: nowrap;
    overflow: hidden;

    &:after {
      background-color: white;
      top: 1px;
      height: 38px;
      width: 40px;
      text-align: center;
      right: 1px;
    }
    img {
      margin-top:5px;
      margin-right:5px;
    }
  }
  .select_list {
    li {
      img {
        margin-top:5px;
        margin-right:5px;
      }

      &.selected {
        background-color: #eefcee;

        &:before {
          font-family: "fontello";
          display: inline-block;
          content: '\e914';
          margin-right: 5px;
        }
      }
    }
  }
}
.custom_select_sm {
  background: #fafafa;
  .select_title {
    padding: 2px 30px 0px 8px;
    font-size: 0.8em;
    height: 30px;
    &:after {
      top: -1px;
      height: 30px;
      line-height: 30px;
      right: -6px;
      background: none;
    }
  }
  .select_list {
    li {
      padding: 3px 6px 3px;
      font-size: 0.8em;
    }
  }
}

input[type="radio"], input[type="checkbox"] {
  width: 1px;
  height: 1px;
  padding: 0;
  border: none;
  outline: none;
  position: absolute;
}
input[type="radio"]:focus, input[type="checkbox"]:focus {
  outline: none;
}
.has-error input[type="checkbox"]+label:before, .has-error input[type="radio"]+label:before {
  border-color: red;
}
.has-success .form-control {
  border-color: #0a9c0d;
}

.form-input {
  .spinner {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.well {
  .fieldField {
    display: block;

    .fieldLabel {
      font-weight: bold;
      display: inline-block;
      width: 40%;
      white-space: nowrap;
      padding-right: 20px;

      &:after {
        content: ":";
      }
    }
  }
}

.tooltip {

  background:$primarycolor;
  color:#fff;

  &::after {
    border-top-color:$primarycolor;
  }

  &.top, &.bottom, &.left, &.right {
    padding: 5px;
  }
}


/**
    PRODOTTO
 */
.ProductVendorChoice {
  padding: 2px 0;

  &:hover {
    background: #f6f6f6;
  }
}

.productPriceDiscountPerc {
  position: relative;
  top: -2px;
}

.labels_container {
  .label {
    width: 100%;
    padding-bottom: 0;
    height: auto;
    span {
      padding-top:50%;
      padding-bottom: 50%;
    }
  }
}

.product_description {
  .product_description_text {
    &.truncated {
      max-height: 100px;
    }
    max-height: none;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }
}

.productAttributeTab {
  li {
    .stat {
      width: 20%;
      min-width: 160px;
    }
  }
}
.zoomLens {
  z-index: 1;
}
form#addToCart {
  position: relative;
  z-index: 2;
}
.flex-direction-nav a {
  width: 24px;
  height: 24px;
}
.flex-direction-nav .flex-next {
  text-align: center;
}

/**
    SHOPPING CART
 */

#cartForm {

  table tr.cart-row td.cart-row-product-image {
    width: 120px;
  }

  .cart-row {

    &.productNotAvailable {

      position: relative;

      .cart-row-overlay {
        position: absolute;
        left: 0;
        width: 100%;
        padding: 10px;
        border: none;
        background: rgba(255, 255, 255, 0.7);
      }
    }

    .ProductVariantQuantity {
      max-width: 160px;
    }

    .variantQuantityInput {
      input[type=number] {
        height: 26px;
        border: none;
        text-align: center;
      }

      .quantity {
        position: relative;
        overflow: visible;

        > button {
          height: 26px;
        }
      }

      .error_message {
        position: absolute;
        top: 26px;
        left: 0;
        min-width: 260px;
      }
    }
  }
}

.Cart_ShoppingCart_ProfileBox, .Order_User_Profile_Attributes {

  padding: 10px;

  span.label {
    height: auto;
    width: auto;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .fieldField {

    .fieldLabel {
      display: none;
    }

    &.company,
    &.recipient,
    &.first-name,
    &.last-name {
      font-weight: bold;
    }

    &.company::after,
    &.recipient::after,
    &.phone::after,
    &.last-name::after {
      content: ' ';
      display: block;
    }

    &.tax-code,
    &.vat-number {
      .fieldLabel {
        display: inline;
        &::before {
          content: ' ';
          display: block;
        }
        &::after {
          content: ': ';
        }
      }
    }

  }

  &:hover {
    background: #f1f1f1;
    cursor: pointer;
  }

  &.Cart_ShoppingCart_Extension_Selected {
    border-color: green;
  }

  &.has-error {
    border-color: red !important;
  }

}

.Cart_ShoppingCart_Extension_Container {

  min-width: 160px;
  position: relative;

  .has-error {
    border-color: red !important;

    .Cart_ShoppingCart_Extension_Selected {
      border-color: red !important;
    }
  }

  .spinner-loader {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -60px;
    margin-top: -60px;
  }

  &.Cart_ShoppingCart_Extension_Loading {

    > div {
      pointer-events: none;
      opacity: 0.3;
    }
    .spinner-loader {
      display: block;
      opacity: 1;
    }

  }

}

#Cart_ShoppingCart_MiniCart {
  .mini-cart-row {
    &.productNotAvailable {
      img {
        opacity: 0.5;
      }
      .item_description {
        > a, > p {
          text-decoration: line-through;
          opacity: 0.5;
        }
      }
    }
  }
}

/*
    PLUGINS
 */

#sliderRevolutionArrowDown {
  background-color: rgba(255,255,255,0.8);
  -webkit-mask-image: url("../img/arrow_down.svg");
  mask-image: url("../img/arrow_down.svg");
  display: block;
  width: 150px;
  height: 77px;
  position: absolute;
  bottom: 20vw;
  left: 50%;
  margin-left: -75px;
  z-index: 100;
}

.ui-widget.ui-widget-content {
  border: 1px solid #e1e4e6;
  background: white;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  li div {
    padding: 5px 10px;

    &.ui-state-active {
      border-color: transparent;
      background: #f1f1f1;
      color: #666;
    }
  }
}

/**
    COMMENTI
 */
.comment {
  &.comment-right {
    &:before {
      left: -18px;
      top: 20px;
      border-top: 10px solid transparent;
      border-right: 8px solid #ecf0f1;
      border-bottom: 10px solid transparent;
    }
  }
  &.comment-left {
    &:before {
      right: -18px;
      left: auto;
      top: 20px;
      border-top: 10px solid transparent;
      border-left: 8px solid #ecf0f1;
      border-bottom: 10px solid transparent;
    }
  }
}

/**
    QUICK SEARCH
 */
.QuickSearchResults {
  position: absolute;
  background: white;
  border: 1px solid #e1e4e6;
  width: 100%;
  padding: 15px 0 0 0;
  border-top: none;
  margin-top: -5px;
  z-index: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  .spinner-loader {
    width: 60px;
    height: 60px;
    margin: 20px auto;
  }

  .QuickSearchTypeTitle {
    padding: 0 10px;
  }

  li {
    padding: 5px 10px;

    &:hover {
      background: #e1e4e6;
    }

    small.label {
      font-size: 60%;
      position: relative;
      top: -2px;

      &.label-default {
        background-color: #a0a0a0;
      }
    }
  }
}

/**
    AREA RISERVATA
 */
.user_orders {
  .user_order {
    table thead {
      .fieldLabel {
        display: none;
      }
    }
    .user_order_number {
      span.label {
        top: -2px;
      }
    }
  }
}

/**
  BOOKING BOX
 */
#ProductBookingBox #sold_out {
  position: absolute;
  bottom: -98px;
  left: 0;
  z-index: 10;
  background: rgba(255,255,255,0.9);
  padding-top: 100px;
}

/**
  SWIPE PANELS
 */
.swipePanel {
  background: white;
  position: fixed;
  height: 100%;
  width: 80%;
  max-width: 350px;
  padding: 20px;
  z-index: 1;

  .swipeCloser {
    position: absolute;
    top: 10px;
    font-size: 26px;
    font-weight: normal;
  }

  &.swipeLeftPanel {
    top: 0;
    left: -350px;

    border-right: 1px solid #ccc;
    box-shadow: 10px 0 10px -12px #ccc;

    .swipeCloser {
      float: right;
      right: 15px;
    }

    &.show {
      -webkit-animation: swipeFromLeft 0.5s forwards;
      animation: swipeFromLeft 0.5s forwards;
    }
  }

  &.swipeRightPanel {
    top: 0;
    right: -350px;

    border-left: 1px solid #ccc;
    box-shadow: 10px 0 10px 7px #ccc;

    .swipeCloser {
      float: left;
      left: 15px;
    }

    &.show {
      -webkit-animation: swipeFromRight 0.5s forwards;
      animation: swipeFromRight 0.5s forwards;
    }
  }

}

@-webkit-keyframes swipeFromLeft {
  100% { left: 0; }
}

@keyframes swipeFromLeft {
  100% { left: 0; }
}
@-webkit-keyframes swipeFromRight {
  100% { right: 0; }
}

@keyframes swipeFromRight {
  100% { right: 0; }
}